import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { BorderlessShadowlessGrayBox, Tabs } from 'components';
import { Routes } from 'enums';
import BuyCreditsPage from './BuyCredits';
import { MerchantOfficeOrders } from './Orders';
import {
  AddressBookTab,
  BrandMaterials,
  MerchantAccountsTab,
  MyDealsTab,
  ReviewsTab,
  PromoCodes,
  StatisticsOverview,
} from './components';
import { MerchantOfficePage, title } from './styles';

const tabs = {
  myDeals: 'my-deals',
  orders: 'orders',
  merchantAccounts: 'merchant-accounts',
  addressBook: 'address-book',
  reviews: 'reviews',
  promoCodes: 'promo-codes',
  stats: 'statistics',
  buyCredits: 'buy-credits',
  brandMaterials: 'brand-materials',
};

const MerchantOffice = () => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const history = useHistory();
  const locationPath = window.location.pathname.split('/').pop();

  const handleTabChange = tab => history.push(`${Routes.merchantOffice}/${tab}`);

  const tabItems = [
    {
      key: tabs.myDeals,
      label: getText(tabs.myDeals),
      children: <MyDealsTab />,
    },
    { key: tabs.orders, label: getText(tabs.orders), children: <MerchantOfficeOrders /> },
    {
      key: tabs.merchantAccounts,
      label: getText(tabs.merchantAccounts),
      children: <MerchantAccountsTab />,
    },
    {
      key: tabs.addressBook,
      label: getText(tabs.addressBook),
      children: <AddressBookTab />,
    },
    {
      key: tabs.reviews,
      label: getText(tabs.reviews),
      children: <ReviewsTab />,
    },
    {
      key: tabs.promoCodes,
      label: getText(tabs.promoCodes),
      children: <PromoCodes />,
    },
    {
      key: tabs.stats,
      label: getText(tabs.stats),
      children: <StatisticsOverview />,
    },
    {
      key: tabs.buyCredits,
      label: getText(tabs.buyCredits),
      children: <BuyCreditsPage />,
    },
    {
      key: tabs.brandMaterials,
      label: getText(tabs.brandMaterials),
      children: <BrandMaterials />,
    },
  ];

  return (
    <MerchantOfficePage theme={theme}>
      <BorderlessShadowlessGrayBox theme={theme}>
        <div className="content-container">
          <h1 className={`${title(theme)}`}>{getText('merchantOffice')}</h1>
        </div>
      </BorderlessShadowlessGrayBox>
      <div className="content-container">
        <Tabs
          destroyInactiveTabPane
          defaultActiveKey={tabs.myDeals}
          activeKey={locationPath}
          onChange={key => handleTabChange(key)}
          items={tabItems}
          theme={theme}
        />
      </div>
    </MerchantOfficePage>
  );
};

export default MerchantOffice;
