import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Popover, Switch } from 'antd';
import { useTranslations } from '@veraio/strank';
import { Button, ConfirmationPopup, Icon } from 'components';
import { businessStatus, businessStatusInverse, Routes } from 'enums';
import { formatDescriptiveDateTime } from 'utils';
import { deleteBusinessAddress, setError } from 'services';
import { popoverWrapper, versionSwitchWrapper } from './styles';
import {
  addressBox,
  topContentWrapper,
  addressTitle,
  infoTextWrapper,
  messageWrapper,
  commentMessage,
} from './addressesStyles';

const AddressCard = ({ address, isPublic, onReload }) => {
  const theme = useTheme();
  const { getText, getDynamicTranslation } = useTranslations();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [isActiveVersion, setIsActiveVersion] = useState(true);
  const history = useHistory();
  const locationPath = window.location.pathname.split('/').pop();
  const query = window.location.search;

  const handleOk = async () => {
    setIsModalVisible(false);
    await deleteAddress(address.id);
    history.push(`${Routes.merchantOffice}/${locationPath}${query}`);
    onReload();
  };

  const deleteAddress = async id => {
    const [, err] = await deleteBusinessAddress(id);
    if (err) return setError(err);
  };

  const getValue = field =>
    !isActiveVersion && (field?.pendingValue || field?.pendingValue === '') ? field?.pendingValue : field?.currentValue;

  return (
    <>
      <div className={addressBox(theme, isPublic)}>
        <div className={topContentWrapper(theme)}>
          <div className={addressTitle(theme)}>
            <h6>{getDynamicTranslation(address.name)?.name}</h6>
            {!isPublic && (
              <Popover
                content={
                  <div className="flex-column">
                    <Button type="link" small linkTo={`/edit-business-address/${address.id}`}>
                      {getText('edit')}
                    </Button>

                    <Button type="link" small onClick={() => setIsModalVisible(true)}>
                      {getText('delete')}
                    </Button>
                  </div>
                }
                overlayClassName={popoverWrapper(theme)}
                zIndex={900}
                placement="bottomRight"
                trigger="click"
              >
                <Icon iconName="las la-ellipsis-v" />
              </Popover>
            )}
          </div>
          <label>{`${getText('status')}: ${businessStatusInverse[address.statusId]}`}</label>
          {address.managerStatusUpdateMessage && (
            <div className={messageWrapper(theme)}>
              <p>
                <Icon iconName="las la-question-circle" />
                {getText('updatedOnBy', {
                  date: formatDescriptiveDateTime(address.managerStatusUpdateDate),
                  by: address.managerStatusUpdateUsername,
                })}
              </p>
              <Button type="link" small onClick={() => setShowMessage(prev => !prev)}>
                {showMessage ? getText('hideMessage') : getText('viewMessage')}
              </Button>
              {showMessage && <p className={commentMessage}>{address.managerStatusUpdateMessage}</p>}
            </div>
          )}
        </div>
        {address?.statusId === businessStatus.edited && (
          <div className={versionSwitchWrapper(theme)}>
            <label>{getText('showActiveVersion')}</label>
            <Switch
              size="small"
              onChange={checked => {
                checked ? setIsActiveVersion(false) : setIsActiveVersion(true);
              }}
            />
            <label>{getText('showVersionUnderRevision')}</label>
          </div>
        )}
        <p className={infoTextWrapper(theme)}>
          <label>{getText('assignMerchantProfiles')}:</label>
          <br />
          {getDynamicTranslation(address.businessNames)?.name}
        </p>
        <p className={infoTextWrapper(theme)}>
          <label>{getText('country')}:</label>
          <br />
          {getValue(address.country)}
        </p>
        <p className={infoTextWrapper(theme)}>
          <label>{getText('city')}:</label>
          <br />
          {getValue(address.city)}
        </p>
        <p className={infoTextWrapper(theme)}>
          <label>{getText('stateProvince')}:</label>
          <br />
          {getValue(address.state)}
        </p>
        <p className={infoTextWrapper(theme)}>
          <label>{getText('postcode')}:</label>
          <br />
          {getValue(address.postcode)}
        </p>
        <p className={infoTextWrapper(theme)}>
          <label>{getText('streetAddress')}:</label>
          <br />
          {getValue(address.street)}
        </p>
        <p className={infoTextWrapper(theme)}>
          <label>{getText('contactPerson')}:</label>
          <br />
          {getValue(address.contactPerson)}
        </p>
        <p className={infoTextWrapper(theme)}>
          <label>{getText('phoneNumber')}:</label>
          <br />+{getValue(address.phoneNumber)}
        </p>
        <p className={infoTextWrapper(theme)}>
          <label>{getText('workDays')}:</label>
          <br />
          {getValue(address.workingDays)}
        </p>
        <p className={infoTextWrapper(theme)}>
          <label>{getText('workingHours')}:</label>
          <br />
          {getValue(address.workingHours)}
        </p>
      </div>
      <ConfirmationPopup
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={() => setIsModalVisible(false)}
        confirmDisabled={!!address?.activeDealsCount}
        text={
          address?.activeDealsCount
            ? getText('cannotDeleteAddressNoActiveDeals', { account: address.activeDealsCount })
            : getText('sureWantToDeleteBusinessAddress')
        }
      />
    </>
  );
};

AddressCard.propTypes = {
  address: PropTypes.object,
  isPublic: PropTypes.bool,
  onReload: PropTypes.func,
};

export default AddressCard;
