import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTheme } from 'emotion-theming';
import { pick } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import {
  changeUserCurrency,
  cryptoSign,
  findFiatCurrency,
  DEFAULT_FIAT_CURRENCY,
  setDisplayFiatOnly,
  useCurrencies,
} from '@oneecosystem/dealshaker-core';
import { Button, Modal, PriceLabel, SwitchButton } from 'components';
import { setCacheVal } from 'utils/cacheUtils';
import { useUser } from 'stores';
import {
  applyButton,
  cancelButton,
  columnContainer,
  columnListOptionsContainer,
  columnTitle,
  itemContainer,
  modalContainer,
} from './styles';

const LocalizationPopup = forwardRef((_, ref) => {
  const { selectedCurrency, fiatCurrencies, displayFiatOnly } = useCurrencies(currenciesState =>
    pick(currenciesState, ['selectedCurrency', 'fiatCurrencies', 'displayFiatOnly']),
  );
  const { getText, language, allEnvLanguages, changeLanguage } = useTranslations();
  const { isAuthenticated } = useUser();
  const theme = useTheme();
  const modalRef = useRef();
  const [selected, setSelected] = useState({ currencyCode: null, languageCode: null, fiatOnly: displayFiatOnly });

  useImperativeHandle(ref, () => ({
    open: () => modalRef.current?.open(),
    close: () => modalRef.current?.close(),
  }));

  useEffect(() => {
    setSelected({ currencyCode: selectedCurrency?.code, languageCode: language?.code, fiatOnly: displayFiatOnly });
  }, [language]);

  const handleSubmit = () => {
    changeLanguage(selected?.languageCode);
    changeUserCurrency(findFiatCurrency(selected?.currencyCode));
    isAuthenticated && setDisplayFiatOnly(selected?.fiatOnly);
    setCacheVal('fiatOnly', selected?.fiatOnly);
    modalRef.current?.close();
  };

  const handleCancel = () => {
    setSelected({ currencyCode: selectedCurrency?.code, languageCode: language?.code, fiatOnly: !isAuthenticated });
    modalRef.current?.close();
  };

  return (
    <Modal ref={modalRef} className={modalContainer(selected?.fiatOnly)(theme)}>
      <div className="modal-content">
        <section className={columnContainer}>
          <h4 className={columnTitle}>{getText('language')}</h4>
          <div className={columnListOptionsContainer}>
            {allEnvLanguages?.map(el => (
              <div
                key={el.code}
                tabIndex={0}
                role="button"
                onClick={() => setSelected(prev => ({ ...prev, languageCode: el.code }))}
                className={itemContainer(el?.code === selected?.languageCode ?? language?.code, theme)}
              >
                <p> {el.nativeName ?? ''}</p>
              </div>
            ))}
          </div>
        </section>

        <div className="vertical-line" />

        <section className={columnContainer}>
          <h4 className={columnTitle}>{getText('currency')}</h4>
          <div className={columnListOptionsContainer}>
            {fiatCurrencies?.map(currencyItem => (
              <div
                key={currencyItem.code}
                tabIndex={0}
                role="button"
                onClick={() => setSelected(prev => ({ ...prev, currencyCode: currencyItem.code }))}
                className={itemContainer(
                  currencyItem?.code === selected?.currencyCode ?? selectedCurrency?.code,
                  theme,
                )}
              >
                <p> {`${currencyItem?.symbol ?? ''} ${currencyItem?.code ?? ''}`}</p>
              </div>
            ))}
          </div>
        </section>
      </div>

      {isAuthenticated && (
        <div className="display-price-container">
          <div className="price-info">
            <h5 className="title">{getText('displayingPrices')}</h5>
            <p className="description">{getText('localizationPriceDescription', { cryptoSign: cryptoSign() })}</p>
          </div>
          <div className="button-container">
            <SwitchButton
              size={20}
              color="blue"
              className="switch-button"
              leftLabel={getText('fiatAndCrypto')}
              rightLabel={getText('fiat')}
              value={selected?.fiatOnly ?? displayFiatOnly}
              onChange={value => setSelected(prev => ({ ...prev, fiatOnly: !value }))}
            />
            <div className="example-container">
              <p className="example-text">{getText('example')}</p>
              <div className="price-styles">
                {selected?.fiatOnly ? (
                  <PriceLabel
                    total={67.5}
                    fiatOriginCurrency={DEFAULT_FIAT_CURRENCY.code}
                    fiatDesiredCurrency={selected?.currencyCode}
                    fiat={25}
                    crypto={1}
                    fiatOnly={selected?.fiatOnly}
                  />
                ) : (
                  <PriceLabel
                    fiat={25}
                    fiatOriginCurrency={DEFAULT_FIAT_CURRENCY.code}
                    fiatDesiredCurrency={selected?.currencyCode}
                    crypto={1}
                    fiatOnly={false}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex">
        <Button type="info" className={applyButton} onClick={handleSubmit}>
          {getText('apply')}
        </Button>
        <Button type="link" className={cancelButton} onClick={handleCancel}>
          {getText('cancel')}
        </Button>
      </div>
    </Modal>
  );
});

export default LocalizationPopup;
