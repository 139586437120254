import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-hot-toast';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Tag } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { stringify } from 'query-string';
import { useTranslations } from '@veraio/strank';
import { pick } from '@veraio/core';
import {
  useCurrencies,
  cryptoSign,
  fiatSign,
  convertCurrencies,
  useLocations,
  removeShippingFrom,
  removeShippingTo,
} from '@oneecosystem/dealshaker-core';
import { CategoryFilter } from 'components';
import {
  SearchInput,
  RangeSlider,
  ListingRatingFilter,
  TextInput,
  SortDropdown,
  BorderlessShadowlessGrayBox,
} from 'components/ui';
import { BaseCheckbox } from 'components/ui/Checkboxes';
import { Button, useUrlParams, useDeepEffect } from 'components/UIExternal';
import { locationsModalRef } from 'components/popups/LocationPopup';
import { useUser } from 'stores';
import { deliveryMethod, deliveryMethodSelect } from 'enums';
import { delay } from 'utils/queryUtils';
import useError from 'services/errorHandling/useError';
import { getDealListing } from 'services';
import { getAllListingFilters, saveListingFilter, deleteFilterById } from 'services/api/listingFilterService';
import { mb, mt } from 'assets/css/globalCSS';
import SavedFiltersPanel from './components/SavedFiltersPanel';
import { mainContainer, checkboxesWrapper, categoriesContainer, sortDropdown, shippingBoxWrapper } from './styles';

const FilterDealsPanel = ({ initialValues, onChange, priceRangesForDeals, sortingValue, searchText }) => {
  const { getText } = useTranslations();
  const theme = useTheme();
  const { setQueryParams } = useUrlParams();
  const { displayFiatOnly, selectedCurrency } = useCurrencies(currenciesState =>
    pick(currenciesState, ['displayFiatOnly', 'selectedCurrency']),
  );
  const { shippingFrom, shippingTo } = useLocations();
  const { isAuthenticated } = useUser();
  const { setError } = useError();
  const [nameForFilterSave, setNameForFilterSave] = useState('');
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [reloadFilters, setReloadFilters] = useState(false);
  const [allSavedFilters, setAllSavedFilters] = useState([]);
  const [filterValues, setFilterValues] = useState({
    categoryId: null,
    isAvailableNow: false,
    isFeatured: false,
    isPriceOneOnly: null,
    priceEuroAbove: null,
    priceEuroBelow: null,
    priceOneAbove: null,
    priceOneBelow: null,
    discountAbove: null,
    discountBelow: null,
    rating: null,
    username: null,
    deliveryMethods: [],
    ...initialValues,
  });
  const [selectedDeliveryMethods, setSelectedDeliveryMethods] = useState(filterValues?.deliveryMethods || []);

  useEffect(() => {
    isAuthenticated &&
      getAllListingFilters(setError).then(res => {
        setAllSavedFilters(res);
        setReloadFilters(false);
      });
  }, [disableSaveButton, reloadFilters, isAuthenticated]);

  useEffect(() => {
    onChange(filterValues);
  }, [filterValues]);

  useDeepEffect(() => {
    setQueryParams({ from: shippingFrom.map(el => el.id), to: shippingTo.map(el => el.id) });
  }, [shippingFrom, shippingTo]);

  const getHandleFilterChange = prop => value => {
    delay(() => {
      const updatedFilterValues = { ...filterValues };
      updatedFilterValues[prop] = value;
      if (searchText) updatedFilterValues.searchText = searchText;

      window.history.pushState({}, null, modifyUrl(prop, value));
      onChange(updatedFilterValues);
      setFilterValues(updatedFilterValues);
    });
  };

  const modifyUrl = (prop, value) => {
    const currentUrl = window.location.href;
    let urlArray = currentUrl.split('&');
    const foundPropIndex = urlArray.findIndex(data => data.includes(prop));
    if (foundPropIndex > -1) {
      if (value) {
        if (prop === 'deliveryMethods') {
          const temp = [...value];
          const currentUrl = urlArray.filter(data => !data.includes(prop));
          temp.forEach(item => currentUrl.push(`${prop}=${item}`));
          /* eslint-disable no-param-reassign */
          urlArray = currentUrl;
        } else {
          typeof value === 'object' && value.length === 0
            ? urlArray.splice(foundPropIndex, 1)
            : (urlArray[foundPropIndex] = `${prop}=${value}`);
        }
      } else urlArray.splice(foundPropIndex, 1);
    } else urlArray.push(`${prop}=${value}`);

    const joinedProps = urlArray.join('&');
    if (joinedProps.includes('listing')) return joinedProps;

    return `/listing?${joinedProps}`;
  };

  const loadFilterUrl = filter => {
    window.history.pushState(
      {},
      null,
      `/listing?${filter?.categoryId ? `categoryId=${filter?.categoryId}` : ''}${
        filter?.rating ? `&rating=${filter?.rating}` : ''
      }${
        filter?.deliveryMethods?.length
          ? `&${stringify({ deliveryMethods: filter?.deliveryMethods }, { arrayFormat: 'none' })}`
          : ''
      }${filter?.isFeatured ? `&isFeatured=${filter?.isFeatured}` : ''}${
        filter?.isAvailableNow ? `&isAvailableNow=${filter?.isAvailableNow}` : ''
      }${filter?.isPriceOneOnly ? `&isPriceOneOnly=${filter?.isPriceOneOnly}` : ''}${
        filter?.discountAbove ? `&discountAbove=${filter?.discountAbove}` : ''
      }${filter?.searchText ? `&searchText=${filter?.searchText}` : ''}${
        filter?.dealsSorting || filter?.dealsSorting === 0 ? `&dealsSorting=${filter?.dealsSorting}` : ''
      }`,
    );

    setSelectedDeliveryMethods(filter?.deliveryMethods);
    setFilterValues(filter);
  };

  const onSaveFilterNameChange = e => {
    setNameForFilterSave(e.target.value);
    setDisableSaveButton(false);
  };

  const saveFilter = () => {
    saveListingFilter(nameForFilterSave, filterValues, setError).then(() => {
      setDisableSaveButton(true);
      setNameForFilterSave('');
      toast.success(getText('filterSavedSuccessfully'), {
        variant: 'success',
      });
    });
  };

  const deleteFilter = id => {
    deleteFilterById(id, setError).then(() => {
      setReloadFilters(true);
      toast.success(getText('filterDeletedSuccessfully'), { id });
    });
  };

  const onIsAvailableChange = getHandleFilterChange('isAvailableNow');
  const onRatingChange = getHandleFilterChange('rating');
  const onIsFeaturedChange = getHandleFilterChange('isFeatured');
  const onIsPriceOneOnlyChange = getHandleFilterChange('isPriceOneOnly');
  const deliveryChangeHandler = getHandleFilterChange('deliveryMethods');
  const sortingChangeHandler = getHandleFilterChange('dealsSorting');

  useEffect(() => {
    (sortingValue || sortingValue === 0) && sortingChangeHandler(sortingValue);
  }, [sortingValue]);

  const onDeliveryChange = (id, value) => {
    let updatedDeliveryMethods = [...selectedDeliveryMethods];
    const tempId = id;

    if (value && !updatedDeliveryMethods.find(method => method === tempId || method === id))
      updatedDeliveryMethods.push(tempId);
    else if (!value && updatedDeliveryMethods.find(method => method === tempId || method === id))
      updatedDeliveryMethods = updatedDeliveryMethods.filter(item => item !== tempId).filter(item => item !== id);

    setSelectedDeliveryMethods(updatedDeliveryMethods);
    deliveryChangeHandler(updatedDeliveryMethods);
  };

  return (
    <div className={mainContainer(theme)}>
      <h5 className="section-subtitle">{getText('categories')}</h5>
      <div className={categoriesContainer(theme)}>
        <CategoryFilter onFetchData={getDealListing} />
      </div>

      <SearchInput
        allowClear
        placeholder={getText('searchByMerchantUsername')}
        onSearch={getHandleFilterChange('username')}
      />
      <div className={checkboxesWrapper}>
        <BaseCheckbox
          theme={theme}
          isBold
          checked={filterValues?.isAvailableNow}
          onChange={e => onIsAvailableChange(e.target.checked)}
        >
          {getText('availableNow')}
        </BaseCheckbox>
        <BaseCheckbox
          theme={theme}
          isBold
          checked={filterValues?.isFeatured}
          onChange={e => onIsFeaturedChange(e.target.checked)}
        >
          {getText('featuredDeals')}
        </BaseCheckbox>
        <BaseCheckbox
          theme={theme}
          isBold
          checked={filterValues?.isPriceOneOnly}
          onChange={e => onIsPriceOneOnlyChange(e.target.checked)}
        >
          100% {cryptoSign()}
        </BaseCheckbox>
      </div>
      {isAuthenticated && (
        <>
          <h5 className={`section-subtitle underline ${mb(0)}`}>{getText('filters')}</h5>
          <SavedFiltersPanel
            allSavedFilters={allSavedFilters}
            loadFilterUrl={loadFilterUrl}
            deleteFilter={deleteFilter}
            clearFilter={setFilterValues}
            clearParentFilter={onChange}
          />
        </>
      )}
      <div className="flex space-between">
        <h6 className="section-subtitle underline">{getText('dealsLocation')}</h6>
        <Button type="link" onClick={() => locationsModalRef?.open('1')}>
          {getText('change')}
        </Button>
      </div>
      {shippingFrom?.length ? (
        <BorderlessShadowlessGrayBox className={shippingBoxWrapper(theme)} theme={theme}>
          {shippingFrom.map(country => (
            <Tag
              closable
              key={country.id}
              title={country.name?.length > 30 ? country.name : null}
              onClose={() => removeShippingFrom(country.id)}
            >
              {country.name?.length > 30 ? country.name.substring(0, 30).concat('...') : country.name}
            </Tag>
          ))}
        </BorderlessShadowlessGrayBox>
      ) : (
        <BorderlessShadowlessGrayBox className={shippingBoxWrapper(theme)} theme={theme}>
          <span className="ant-tag">{getText('worldwide')}</span>
        </BorderlessShadowlessGrayBox>
      )}
      <div className="flex space-between">
        <h6 className="section-subtitle underline">{getText('shippingTo')}</h6>
        <Button type="link" onClick={() => locationsModalRef?.open('2')}>
          {getText('change')}
        </Button>
      </div>
      {shippingTo?.length ? (
        <BorderlessShadowlessGrayBox className={shippingBoxWrapper(theme)} theme={theme}>
          {shippingTo.map(country => (
            <Tag
              closable
              key={country.id}
              title={country.name?.length > 30 ? country.name : null}
              onClose={() => removeShippingTo(country.id)}
            >
              {country.name?.length > 30 ? `${country.name.substring(0, 30)}...` : country.name}
            </Tag>
          ))}
        </BorderlessShadowlessGrayBox>
      ) : (
        <BorderlessShadowlessGrayBox className={shippingBoxWrapper(theme)} theme={theme}>
          <span className="ant-tag">{getText('worldwide')}</span>
        </BorderlessShadowlessGrayBox>
      )}
      <div className={mb(30)}>
        <h6 className="section-subtitle underline">{getText('priceRange')}</h6>
        {priceRangesForDeals && (
          <>
            <RangeSlider
              min={Math.round(convertCurrencies({ fiat: priceRangesForDeals?.priceEurMin }).fiatLocal || 0)}
              max={Math.round(convertCurrencies({ fiat: priceRangesForDeals?.priceEurMax }).fiatLocal || 0)}
              onMinValueChange={val =>
                getHandleFilterChange('priceEuroAbove')(
                  convertCurrencies({ fiat: val, fiatOriginCurrency: selectedCurrency.code }).fiatBase,
                )
              }
              onMaxValueChange={val =>
                getHandleFilterChange('priceEuroBelow')(
                  convertCurrencies({ fiat: val, fiatOriginCurrency: selectedCurrency.code }).fiatBase,
                )
              }
              label={fiatSign()}
            />
            {isAuthenticated && !displayFiatOnly && (
              <RangeSlider
                min={Math.round(priceRangesForDeals?.priceOneMin) || 0}
                max={Math.round(priceRangesForDeals?.priceOneMax) || 0}
                onMinValueChange={getHandleFilterChange('priceOneAbove')}
                onMaxValueChange={getHandleFilterChange('priceOneBelow')}
                label={cryptoSign()}
              />
            )}
          </>
        )}
      </div>
      <h6 className="section-subtitle underline">{getText('discountRange')}</h6>
      <RangeSlider
        min={0}
        max={100}
        onMinValueChange={getHandleFilterChange('discountAbove')}
        onMaxValueChange={getHandleFilterChange('discountBelow')}
        label="%"
      />
      <h6 className="section-subtitle underline mt-30">{getText('deliveryMethod')}</h6>
      <div className={checkboxesWrapper}>
        <BaseCheckbox
          theme={theme}
          checked={filterValues?.deliveryMethods?.indexOf(deliveryMethod.selfOrganizedShipping) !== -1}
          onChange={e => onDeliveryChange(deliveryMethod.selfOrganizedShipping, e.target.checked)}
        >
          <span>{getText(deliveryMethodSelect[deliveryMethod.selfOrganizedShipping].label)}</span>
          <FeatherIcon icon="truck" size={18} strokeWidth={1} />
        </BaseCheckbox>
        <BaseCheckbox
          theme={theme}
          checked={filterValues?.deliveryMethods?.indexOf(deliveryMethod.redeemAtLocation) !== -1}
          onChange={e => onDeliveryChange(deliveryMethod.redeemAtLocation, e.target.checked)}
        >
          <span>{getText(deliveryMethodSelect[deliveryMethod.redeemAtLocation].label)}</span>
          <FeatherIcon icon="map-pin" size={18} strokeWidth={1} />
        </BaseCheckbox>
        <BaseCheckbox
          theme={theme}
          checked={filterValues?.deliveryMethods?.indexOf(deliveryMethod.onlineService) !== -1}
          onChange={e => onDeliveryChange(deliveryMethod.onlineService, e.target.checked)}
        >
          <span>{getText(deliveryMethodSelect[deliveryMethod.onlineService].label)}</span>
          <FeatherIcon icon="globe" size={18} strokeWidth={1} />
        </BaseCheckbox>
      </div>
      <div className={mb({ lg: 15, span: 15 })}>
        <h6 className="section-subtitle underline rating-title">{getText('customerRating')}</h6>
        <ListingRatingFilter
          value={Number(filterValues?.rating) || 0}
          onRateChange={value => {
            value ? onRatingChange(value) : onRatingChange(null);
          }}
        />
      </div>
      {isAuthenticated && (
        <div className="merchant-save-filter">
          <TextInput
            theme={theme}
            value={nameForFilterSave}
            onChange={onSaveFilterNameChange}
            placeholder={getText('enterFilterName')}
          />
          <Button
            type="info"
            small
            fullWidth
            disabled={disableSaveButton}
            onClick={saveFilter}
            className={mt({ lg: 10 })}
          >
            {getText('saveFilter')}
          </Button>
        </div>
      )}
      {isMobile && <SortDropdown inputClass={sortDropdown} />}
    </div>
  );
};

FilterDealsPanel.propTypes = {
  initialValues: PropTypes.object,
  preSelectedCategoryId: PropTypes.any,
  priceRangesForDeals: PropTypes.object,
  onChange: PropTypes.func,
  sortingValue: PropTypes.number,
  searchText: PropTypes.string,
};

export default FilterDealsPanel;
