import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Rate, Row, Col, Popover, Slider } from 'antd';
import { useTheme } from 'emotion-theming';
import { Link } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import FeatherIcon from 'feather-icons-react';
import { Button } from 'components/UIExternal';
import DiscountStamp from 'components/deal/DiscountStamp';
import DealPrice from 'components/shared/DealPrice';
import CloseConfirmationPopup from 'components/popups/CloseConfirmationPopup';
import PromotionsModal from 'components/PromotionsModal';
import { dealStatusInverse, dealStatus } from 'enums';
import { formatDefaultDate, formatDescriptiveDateTime24h } from 'utils';
import { changeDealStatus, cloneDeal } from 'services/api/dealsService';
import useError from 'services/errorHandling/useError';
import placeholderThumbnail from 'assets/images/placeholderThumbnail.svg';
import { blackTextLink } from 'assets/css/globalCSS';
import DealQRCodeBlock from './DealQR';
import {
  thumbnailContainer,
  thumbnail,
  dealDiscountStamp,
  imgTag,
  infoWrapper,
  popoverWrapper,
  dealTitle,
  labelsWrapper,
  dateCouponsWrapper,
  starRatings,
  ratingsCountLabel,
  progressBar,
  sliderWrapper,
  messageWrapper,
  commentMessage,
  dealOptionsWrapper,
} from './dealsStyles';

const DealCard = ({ deal, reloadPage, setPromotedDeals, setAvailableCredits, activeDealsLeft }) => {
  const { getText, getDynamicTranslation } = useTranslations();
  const theme = useTheme();
  const { setError } = useError();

  const {
    id,
    name,
    media,
    discount,
    isFeatured,
    statusId,
    dealEndDate,
    availableCouponsCount,
    couponsCount,
    rating,
    reviewsCount,
    progressStepId,
    managerStatusUpdateMessage,
    managerStatusUpdateDate,
    managerStatusUpdateUsername,
  } = deal;

  const [showMessage, setShowMessage] = useState(false);
  const [isPromotionsModalVisible, setIsPromotionsModalVisible] = useState(false);
  const [closeDealModalVisible, setCloseDealModalVisible] = useState(false);

  const showHidePromotionsModal = () => {
    setIsPromotionsModalVisible(!isPromotionsModalVisible);
  };

  const handleOk = () => {
    setCloseDealModalVisible(false);
    handleChangeStatus();
  };

  const handleCancel = () => {
    setCloseDealModalVisible(false);
  };

  const handleCloneDeal = async () => {
    const [, err] = await cloneDeal(id);
    err ? setError(err) : reloadPage();
  };

  const handleChangeStatus = async () => {
    const [, err] = await changeDealStatus(id, dealStatus.closed);
    err ? setError(err) : reloadPage();
  };

  const handleDealTitle = title => (title?.length > 30 ? `${title.slice(0, 30)}...` : title);

  const dealDetails = getDynamicTranslation(deal.dealDetails);
  const businessName = getDynamicTranslation(deal?.businessName);
  const dealTitleHandler = handleDealTitle(dealDetails?.title);

  return (
    <>
      <Row style={{ marginBottom: 40 }} gutter={[24, 24]}>
        <Col className="gutter-row" lg={8} span={24}>
          <Button type="link" linkTo={`/deal/${name}`} key={id} className={thumbnailContainer}>
            <img src={media?.url || placeholderThumbnail} alt="Deal" className={thumbnail} />
            {discount > 0 && <DiscountStamp deal={deal} className={dealDiscountStamp} />}
            {discount > 0 && <div className={imgTag(theme, isFeatured)}>{getText('promo')}</div>}
            {isFeatured && <div className={imgTag(theme, isFeatured)}>{getText('featured')}</div>}
          </Button>
        </Col>
        <Col className="gutter-row" lg={16} span={24}>
          <div className={infoWrapper}>
            <div>
              <h2 className={dealTitle}>{dealTitleHandler}</h2>
              <DealPrice deal={deal} dataId="merchant-office-deal-price-panel" />
              <div className={labelsWrapper(theme)}>
                <span>{getText(dealStatusInverse[statusId])}</span>
                <span>{businessName?.name}</span>
              </div>
              <div className={dateCouponsWrapper}>
                <span className="icon-Ends-on" />
                <span>{getText('endDate')} </span>
                <span className="mr12 bold-text">
                  {dealEndDate && dealEndDate !== '' ? formatDefaultDate(dealEndDate) : 'N/A'}
                </span>
                <div>
                  <span className="icon-Coupon-2" />
                  <span>{`${getText('coupons')}: `}</span>
                  <span>{couponsCount > 0 ? `${availableCouponsCount}/${couponsCount}` : 'N/A'}</span>
                </div>
              </div>
              {reviewsCount > 0 && (
                <div className="deal-rating-wrapper mr12">
                  <Rate className={starRatings(theme)} value={rating} disabled />
                  <label className={ratingsCountLabel(theme)}>{reviewsCount}</label>
                </div>
              )}
            </div>
            <div className={dealOptionsWrapper}>
              {(statusId === dealStatus.approved ||
                statusId === dealStatus.expired ||
                statusId === dealStatus.soldOut) && (
                <DealQRCodeBlock
                  link={`${window.location.host}/deal/${name}`}
                  dealTitle={dealDetails?.title}
                  businessName={businessName?.name}
                  deal={deal}
                />
              )}
              <Popover
                content={
                  <>
                    {statusId === dealStatus.suspended && activeDealsLeft > 0 && (
                      <Button type="link" small onClick={handleChangeStatus} className={blackTextLink(theme)}>
                        {getText('reactivate')}
                      </Button>
                    )}
                    <Button
                      type="link"
                      small
                      className={blackTextLink(theme)}
                      onClick={() => {
                        setIsPromotionsModalVisible(true);
                      }}
                    >
                      {getText('promote')}
                    </Button>
                    <Button type="link" small onClick={handleCloneDeal} className={blackTextLink(theme)}>
                      {getText('duplicate')}
                    </Button>
                    <Button type="link" small linkTo={`/edit-deal/${id}`} className={blackTextLink(theme)}>
                      {getText('edit')}
                    </Button>
                    {statusId !== dealStatus.closed && (
                      <Button
                        type="link"
                        small
                        onClick={() => setCloseDealModalVisible(true)}
                        className={blackTextLink(theme)}
                      >
                        {getText('closeDeal')}
                      </Button>
                    )}
                  </>
                }
                overlayClassName={popoverWrapper(theme)}
                zIndex={900}
                placement="bottomRight"
                trigger="click"
              >
                <FeatherIcon icon="more-vertical" size={15} />
              </Popover>
            </div>
          </div>
          {statusId === dealStatus.suspended && (
            <div className={sliderWrapper(theme)}>
              <Slider value={progressStepId * 20} className={progressBar(theme)} />
              <Link to={`/edit-deal/${id}`}>{getText('continueEditing')}</Link>
            </div>
          )}
          {managerStatusUpdateMessage && (
            <div className={messageWrapper(theme)}>
              <p>
                <FeatherIcon icon="info" size={15} />
                {getText('updatedOnBy', {
                  date: formatDescriptiveDateTime24h(managerStatusUpdateDate),
                  by: managerStatusUpdateUsername,
                })}
              </p>
              <Button type="link" small onClick={() => setShowMessage(prev => !prev)}>
                {showMessage ? getText('hideMessage') : getText('viewMessage')}
              </Button>
              {showMessage && <p className={commentMessage}>{managerStatusUpdateMessage}</p>}
            </div>
          )}
        </Col>
      </Row>
      <CloseConfirmationPopup isModalVisible={closeDealModalVisible} handleOk={handleOk} handleCancel={handleCancel} />
      <PromotionsModal
        isModalVisible={isPromotionsModalVisible}
        showHideModal={showHidePromotionsModal}
        dealId={id}
        setPromotedDeals={setPromotedDeals}
        setAvailableCredits={setAvailableCredits}
      />
    </>
  );
};

DealCard.propTypes = {
  deal: PropTypes.object,
  reloadPage: PropTypes.func,
  setPromotedDeals: PropTypes.func,
  setAvailableCredits: PropTypes.func,
  activeDealsLeft: PropTypes.number,
};

export default DealCard;
